import { faGithub } from '@fortawesome/free-brands-svg-icons/faGithub';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons/faEnvelope';
import { faMedium } from '@fortawesome/free-brands-svg-icons';
// See https://fontawesome.com/icons?d=gallery&s=brands,regular&m=free
// to add other icons.

const data = [
  {
    link: 'https://github.com/ADongol123',
    label: 'Github',
    icon: faGithub,
  },
  // {
  //   link: 'https://www.facebook.com/aayush.dongol.1',
  //   label: 'Facebook',
  //   icon: faFacebookF,
  // },
  {
    link: 'https://www.linkedin.com/in/aayush-dongol-372a371b0/',
    label: 'LinkedIn',
    icon: faLinkedinIn,
  },
  {
    link: 'https://medium.com/@ayussh222dongol',
    label: 'Medium',
    icon: faMedium,
  },
  {
    link: 'mailto:ayussh222dongol@gmail.com',
    label: 'Email',
    icon: faEnvelope,
  },
];

export default data;
