import LinkedIn from "../images/linkedin.jpg";
const cerdata = [
  {
    id: 1,
    icon: LinkedIn,
    name: "Data Science Foundation: Data Engineering",
  },
  {
    id: 2,
    icon: LinkedIn,
    name: "SQL Programming",
  },
  {
    id: 3,
    icon: LinkedIn,
    name: "Data Science Foundation: Data Engineering",
  },
  {
    id: 4,
    icon: LinkedIn,
    name: "Data Science Foundation: Data Engineering",
  },
];

export default cerdata;
